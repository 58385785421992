import styled from 'styled-components'

import categoryToColors from 'components/categoryToColors'
import { NoteHeader, Note } from 'components/note'
import { OutlineBox, OutlineItem } from 'components/best-practices'
import OutlineHeader from 'components/outline-header'
import { headerMargin } from 'components/header'

const Body = styled('div')`
  border-width: 0 1.25em 1.25em 1.25em;
  border-color: ${ props => categoryToColors[props['data-category']] ? categoryToColors[props['data-category']].darkColor : '#C62340'};
  border-style: solid;
  flex: 1 0 auto;
  position: relative;
  padding: 74px 38px;
  z-index: 2;
  ${headerMargin}
  @media (min-width: 833px) {
    padding: 15% 38px;
    border-width: 0 1.5em 1.5em 1.5em;
  }
  &[data-category="Market"] {
    ${NoteHeader}, ${OutlineHeader} {
      color: ${categoryToColors['Market'].color}
    }
    ${OutlineBox}, ${OutlineItem} {
      border-color: ${categoryToColors['Market'].color};
    }
  }
  &[data-category="Product"] {
    ${NoteHeader}, ${OutlineHeader} {
      color: ${categoryToColors['Product'].color}
    }
    ${OutlineBox}, ${OutlineItem} {
      border-color: ${categoryToColors['Product'].color};
    }
  }
  &[data-category="Customer"] {
    ${NoteHeader}, ${OutlineHeader} {
      color: ${categoryToColors['Customer'].color}
    }
    ${OutlineBox}, ${OutlineItem} {
      border-color: ${categoryToColors['Customer'].color};
    }
  }
  ${Note} + ${Note} {
    @media (min-width: 1024px) {
      margin-top: 433px;
    }
  }
`

export default Body
