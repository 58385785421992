import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import marked from 'marked'

import Layout from 'components/layout'
import Header from 'components/header'
import Seperator from 'components/seperator'
import Footer from 'components/footer'
import ArticleSubtitle from 'components/article-subtitle'
import Body from 'components/article-body'
import ArticleContext from 'components/article-context'
import BodyCopy from 'components/body-copy'
import SocialIcons from 'components/social-icons'

import categoryToColors from 'components/categoryToColors'
import components, { AutoMarkdown, imgToPublicUrl } from 'components/components'

import Note from 'components/note'
import Quote from 'components/quote'
import BestPractices, { BestPracticesText, BestPracticesItems, BestPracticesItem } from 'components/best-practices'
import ReadNext from 'components/read-next'
import Grid, { GridItem } from 'components/grid'
import ProCon, { ProConItem, ProConColumn } from 'components/pro-con'
import Table, { TableItem } from 'components/table'
import Video from 'components/video'
import Image from 'components/image'
import CaseStudy from 'components/case-study'
import ImageQuote from 'components/image-quote'

import Mac1Img from 'img/mac1.svg'
import Mac2Img from 'img/mac2.svg'
import Mac3Img from 'img/mac3.svg'

const Mac1Svg = ({ color = '#FF8F99' }) => (
  <svg width="348" height="348" viewBox="0 0 348 348" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M87.0624 348C87.0624 299.854 48.1463 260.938 1.14168e-05 260.938L1.52116e-05 232C64.1119 232 116 283.888 116 348L87.0624 348Z" fill="#4A4A4A"/>
    <path d="M232 260.938C280.146 260.938 319.062 299.854 319.062 348L348 348C348 283.888 296.112 232 232 232L232 260.938Z" fill="#4A4A4A"/>
    <path opacity="0.3" d="M232 87.0624C280.146 87.0624 319.063 48.1463 319.063 1.90462e-05L348 2.2841e-05C348 64.1119 296.112 116 232 116L232 87.0624Z" fill={color}/>
    <path opacity="0.3" d="M144.938 116C144.938 164.146 183.854 203.062 232 203.062L232 232C167.888 232 116 180.112 116 116L144.938 116Z" fill={color}/>
  </svg>
)
const Mac2Svg = ({ color = '#FF8F99' }) => (
  <svg width="350" height="306" viewBox="0 0 350 306" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="350" height="306">
      <rect width="350" height="306" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0)">
      <path d="M-9.34801e-05 45.0624C48.1461 45.0624 87.0623 6.14625 87.0623 -42L116 -42C116 22.1119 64.1117 74 -9.72748e-05 74L-9.34801e-05 45.0624Z" fill="#4A4A4A"/>
      <path d="M116 102.938C164.146 102.938 203.062 141.854 203.062 190L232 190C232 125.888 180.112 74 116 74L116 102.938Z" fill="#4A4A4A"/>
      <path opacity="0.3" d="M87.0623 306C87.0623 257.854 48.1461 218.938 -9.53947e-05 218.938L-9.16e-05 190C64.1117 190 116 241.888 116 306L87.0623 306Z" fill={color}/>
    </g>
  </svg>
)
const Mac3Svg = ({ color = '#FF8F99' }) => (
  <svg width="232" height="696" viewBox="0 0 232 696" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M87.0623 348C87.0623 299.854 48.1461 260.938 -0.000110653 260.938L-0.000106859 232C64.1117 232 116 283.888 116 348L87.0623 348Z" fill="#4A4A4A"/>
  <path d="M232 260.938C280.146 260.938 319.062 299.854 319.062 348L348 348C348 283.888 296.112 232 232 232L232 260.938Z" fill="#4A4A4A"/>
  <path d="M116 435.062C164.146 435.062 203.062 396.146 203.062 348L232 348C232 412.112 180.112 464 116 464L116 435.062Z" fill="#4A4A4A"/>
  <path d="M232 492.938C280.146 492.938 319.062 531.854 319.062 580L348 580C348 515.888 296.112 464 232 464L232 492.938Z" fill="#4A4A4A"/>
  <path d="M28.9377 464C28.9376 512.146 67.8538 551.062 116 551.062L116 580C51.8882 580 6.80431e-06 528.112 1.52116e-05 464L28.9377 464Z" fill="#4A4A4A"/>
  <path opacity="0.3" d="M144.938 116C144.938 164.146 183.854 203.062 232 203.062L232 232C167.888 232 116 180.112 116 116L144.938 116Z" fill={color}/>
  <path opacity="0.3" d="M203.062 696C203.062 647.854 164.146 608.938 116 608.938L116 580C180.112 580 232 631.888 232 696L203.062 696Z" fill={color}/>
  </svg>
)

const Mac1Container = styled('div')`
  width: 348px;
  height: 348px;
  background-image: url('${Mac1Img}');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 17.5%;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`
const Mac2Container = styled('div')`
  width: 350px;
  height: 306px;
  background-image: url('${Mac2Img}');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 1em;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`
const Mac3Container = styled('div')`
  width: 232px;
  height: 696px;
  background-image: url('${Mac3Img}');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
  top: 70vh;
  right: 1em;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`
const Mac1 = ({ color }) => (
  <Mac1Container>
    <Mac1Svg color={color} />
  </Mac1Container>
)
const Mac2 = ({ color }) => (
  <Mac2Container>
    <Mac2Svg color={color} />
  </Mac2Container>
)
const Mac3 = ({ color }) => (
  <Mac3Container>
    <Mac3Svg color={color} />
  </Mac3Container>
)
const BodyContainer = styled('div')`
  position: relative;
`

const ArticleBlurb = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 300;
  line-height: 1.75em;
  font-size: .9em;
  color: #4A4A4A;
  margin-bottom: 24px;
  @media (min-width: 833px) {
    line-height: 1.75em;
    font-size: 1.25em;
  }
`
const ArticleSeperator = styled(Seperator)`
  width: 100px;
  margin-bottom: 28px;
  @media (min-width: 833px) {
    width: 200px;
  }
`
const ArticlePageHeader = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${ props => props.color || '#FF8F99'};
  margin-bottom: 20px;
  @media (min-width: 833px) {
    margin-bottom: 26px;
  }
  @media (min-width: 1024px) {
    letter-spacing: 0.08em;
    font-size: 18px;
  }
`
const ArticlePageTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  font-size: 32px;
  color: #4A4A4A;
  letter-spacing: -0.01em;
  @media (min-width: 833px) {
    line-height: 72px;
    font-size: 64px;
    letter-spacing: 0.01em;
  }
`
const BodyContent = styled('div')`
  @media (min-width: 1024px) {
    position: relative;
  }
`
const CloseContainer = styled('div')`

`

const BodyPadding = styled('div')`
  @media (min-width: 1024px) {
    padding: 0 20%;
    width: 81%;
    max-width: 699px;
    position: relative;
  }
`

const Article = (props) => {
  const { navigate, data, location } = props
  if (typeof window !== 'undefined') {
    window.data = data
  }
  //console.log(props)
  const { content, articles } = (data || {})
  const { frontmatter, rawMarkdownBody } = (content || {})
  const { category, title, subtitle, description, path, sections, caseStudies, readNexts } = (frontmatter ? frontmatter : props)
  const { color, darkColor } = categoryToColors[category]
  const { href } = (location || {})
  return (
    <Layout checkData={true} data={data} fullHeight={true}>
      <Helmet>
        <title>{title} - Lessons in Courseware Development</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={subtitle} />
      </Helmet>
      <Header color="#FFFFFF" background={darkColor} articleOffset={true} showClose={true} navigate={navigate} invertMenu={true} />
      <BodyContainer>
        <Body outlineColor={darkColor} data-category={category}>
          <BodyContent>
            <BodyPadding>
              {category!=='About' ? (
                <ArticlePageHeader color={color}>
                  { category }-related Decisions
                </ArticlePageHeader>
              ) :
                <ArticlePageHeader color={color}>
                  About
                </ArticlePageHeader>}
              <ArticlePageTitle>
                { title }
              </ArticlePageTitle>
              <ArticleSubtitle>
                { subtitle }
              </ArticleSubtitle>
              <ArticleBlurb>
                <AutoMarkdown>
                  { description || '' }
                </AutoMarkdown>
              </ArticleBlurb>
              <ArticleSeperator color={color} />
            </BodyPadding>
            {(sections || []).map(({ body, notes, quotes, videos, images, grids, procons, bestPractices, imageQuotes, tables }, i) => (
              <React.Fragment key={`section:${i}`}>
                <BodyPadding>
                  <BodyCopy>
                    <AutoMarkdown>
                      { body || '' }
                    </AutoMarkdown>
                  </BodyCopy>
                  {(notes || []).map(({ title, body, pushRight }, j) => (
                    <Note title={title} pushRight={pushRight} color={color} key={`notes:${i}:${j}`}>
                      { body }
                    </Note>
                  ))}
                  {(quotes || []).map(({ attribution, body }, j) => (
                    <Quote attribution={attribution} key={`quotes:${i}:${j}`}>
                      { body }
                    </Quote>
                  ))}
                  {(videos || []).map(({ source, caption }, j) => (
                    <Video src={source} key={`videos:${i}:${j}`}>
                      {caption}
                    </Video>
                  ))}
                  {(images || []).map(({ source, caption, ...other }, j) => (
                    <Image src={imgToPublicUrl(source)} {...other} key={`images:${i}:${j}`}>
                      {caption}
                    </Image>
                  ))}
                  {(grids || []).map(({ title, gridItems }, j) => (
                    <Grid title={title} key={`grid:${i}:${j}`}>
                      {(gridItems || []).map(({ title, image, body }) => (
                        <GridItem title={title} image={imgToPublicUrl(image)}>
                          { body }
                        </GridItem>
                      ))}
                    </Grid>
                  ))}
                  {(procons || []).map(({ colTitle1, colItems1, colTitle2, colItems2 }, j) => (
                    <ProCon key={`procons:${i}:${j}`}>
                      <ProConColumn title={colTitle1}>
                        {(colItems1 || []).map(({ title, body }) => (
                          <ProConItem title={title}>
                            { body }
                          </ProConItem>
                        ))}
                      </ProConColumn>
                      <ProConColumn title={colTitle2}>
                        {(colItems2 || []).map(({ title, body }) => (
                          <ProConItem title={title}>
                            { body }
                          </ProConItem>
                        ))}
                      </ProConColumn>
                    </ProCon>
                  ))}
                </BodyPadding>
                {(imageQuotes || []).map(({  quoteBody, ...other }, j) => (
                  <ImageQuote growBody={true} mobilePadding={true} {...other} key={`imageQuotes:${i}:${j}`}>
                    { quoteBody }
                  </ImageQuote>
                ))}
                <BodyPadding>
                  {(tables || []).map(({ items }, j) => (
                    <Table key={`tables:${i}:${j}`}>
                      {(items || []).map(({ title, body }) => (
                        <TableItem title={title}>
                          { body }
                        </TableItem>
                      ))}
                    </Table>
                  ))}
                  {(bestPractices || []).map(({ body, title, items }, j) => (
                    <BestPractices title={title} key={`bestPractices:${i}:${j}`}>
                      { body ? (
                        <BestPracticesText>{body}</BestPracticesText>
                      ) : null }
                      <BestPracticesItems>
                        {(items || []).map(({ body }) => (
                          <BestPracticesItem>
                            { body }
                          </BestPracticesItem>
                        ))}
                      </BestPracticesItems>
                    </BestPractices>
                  ))}
                </BodyPadding>
              </React.Fragment>
            ))}
            <BodyPadding>
              {(caseStudies || []).map(({ body, image, ...other }, i) => (
                <CaseStudy image={image} {...other} key={`caseStudies:${i}`}>
                  { body }
                </CaseStudy>
              ))}
              {(readNexts || []).map(({ article, ...other }, i) => (
                <ArticleContext.Provider value={{ path, title, description, href }} key={`readNexts:${i}`}>
                  <ReadNext articles={articles} article={article} {...other} />
                </ArticleContext.Provider>
              ))}
            </BodyPadding>
            <SocialIcons />
          </BodyContent>
        </Body>
      </BodyContainer>
      <Footer />
    </Layout>
  )
}

export default Article

export const pageQuery = graphql`
  query Article($id: String!) {
    articles: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "article"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            path
            title
            subtitle
          }
        }
      }
    }
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        category
        title
        subtitle
        description
        sections {
          body
          notes {
            title
            body
            pushRight
          }
          quotes {
            attribution
            body
          }
          videos {
            source
            caption
          }
          images {
            source {
              publicURL
            }
            caption
            enableLightbox
          }
          grids {
            title
            gridItems {
              title
              body
              image {
                publicURL
              }
            }
          }
          procons {
            colTitle1
            colItems1 {
              title
              body
            }
            colTitle2
            colItems2 {
              title
              body
            }
          }
          imageQuotes {
            quoteName
            quotePosition
            alignLeft
            quoteBody
            audioFile {
              publicURL
            }
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tables {
            items {
              title
              body
            }
          }
          bestPractices {
            title
            body
            items {
              body
            }
          }
        }
        caseStudies {
          title
          body
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        readNexts {
          article
        }
      }
    }
  }
`
